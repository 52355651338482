/*------------------------------------------------
// File Name:PDM_MA_Appt.js
// File Description:PDM_MA_Appt API方法js接口
// Author:zxl
// Create Time:2023/08/01 16:27:29
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maAppt: "/PDM_MA_Appt"
}

export default api


//-------------------创建预约信息对象-------------------
export function CreatePDM_MA_ApptEntity(parameter) {
    return axios({
        url: api._maAppt + "/CreatePDM_MA_ApptEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约信息列表-------------------
export function GetPDM_MA_ApptList(parameter) {
    return axios({
        url: api._maAppt + "/GetPDM_MA_ApptList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取预约信息列表---------------
export function GetPDM_MA_ApptListByPage(parameter) {
    return axios({
        url: api._maAppt + "/GetPDM_MA_ApptListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约信息对象---------------
export function GetPDM_MA_ApptEntity(parameter) {
    return axios({
        url: api._maAppt + "/GetPDM_MA_ApptEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存预约信息对象---------------
export function UpdatePDM_MA_ApptEntity(parameter) {
    return axios({
        url: api._maAppt+ "/UpdatePDM_MA_ApptEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉预约信息对象---------------
export function DeletePDM_MA_ApptEntity(parameter) {
    return axios({
        url: api._maAppt + "/DeletePDM_MA_ApptEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------保存预约信息对象--大后台使用的添加预约---------------
export function UpdatePDM_MA_ApptEntity2(parameter) {
    return axios({
        url: api._maAppt + "/UpdatePDM_MA_ApptEntity2",
        method: 'post',
        data: parameter
    })
}

/**预约未读条数，根据机构ID获取机构预约未读条数 */
export function GetYuYueCount(parameter) {
    return axios({
        url: api._maAppt + "/GetYuYueCount",
        method: 'post',
        data: parameter
    })
}

/**修改已读状态 */
export function UpdateYuYueState(parameter) {
    return axios({
        url: api._maAppt + "/UpdateYuYueState",
        method: 'post',
        data: parameter
    })
}

