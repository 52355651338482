<template>
  <q-toolbar class="q-pl-xs">
    <q-btn style="min-height: 1vh; max-height: 9vh" icon="notifications">
      <q-badge floating color="red" rounded>{{
        PdmMaApptYuYueCount
      }}</q-badge>
    </q-btn>
    <q-btn
      class="no-shadow"
      flat
      round
      color="grey-11"
      text-color="grey-13"
      icon="fas fa-link"
      size="sm"
    >
      <!-- <q-tooltip> 常用链接 </q-tooltip> -->
      <q-menu>
        <q-list dense style="min-width: 100px">
          <q-item
            v-for="d in links"
            :key="d.LinkID"
            clickable
            v-close-popup
            @click="onJump(d.LinkAddress)"
          >
            <q-item-section title>{{ d.LinkName }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
    <q-separator vertical class="q-ma-sm" />

    <q-btn
      class="no-shadow"
      flat
      round
      color="grey-11"
      text-color="grey-13"
      icon="fas fa-home"
      @click="onBack"
      size="sm"
    >
      <q-tooltip> 返回首页 </q-tooltip>
    </q-btn>
    <q-separator vertical class="q-ma-sm" />

    <q-item dense class="q-pa-none q-mx-md">
      <q-item-section class="q-pa-none">
        <q-item-label class="text-overline" v-if="currentHosp"
          >[{{ currentHosp.HospMICode }}]
        </q-item-label>
        <q-item-label caption>
          <div
            v-if="currentHosp"
            class="ellipsis"
            style="max-width: 160px;color:#333333"
          >
            {{ currentHosp.HospID }}-{{ currentHosp.HospName }}
            <q-tooltip>
              {{ currentHosp.HospID }}-{{ currentHosp.HospName }}
            </q-tooltip>
          </div>
        </q-item-label>
      </q-item-section>
    </q-item>
    <q-separator vertical class="q-ma-sm" />
    <q-btn
      flat
      round
      class="q-ml-sm"
      color="grey-11"
      text-color="grey-13"
      dense
      icon="person"
      @click="ontoggleRightDrawer"
    >
    </q-btn>
  </q-toolbar>
</template>

<script>
import { GettGY_Base_LinkList } from "@/api/gy/base/link";
import * as maAppt from "@/api/pdm/ma/maAppt";
import { mapGetters, mapState } from "vuex";
import msg from "@/config/notify";
export default {
  computed: {
    ...mapGetters("appUser", ["currentHosp", "authHosps"]),
    ...mapState("PdmMaAppt", ["PdmMaApptYuYueCount"]),
  },
  mounted() {
    this.onLoadLinks();
    this.getYuYueCount();
    setInterval(() => {
      this.getYuYueCount();
    }, 60000);
  },
  data() {
    return {
      links: null,
    };
  },
  methods: {
    getYuYueCount() {
      maAppt
        .GetYuYueCount({
          HospID: this.$store.getters["appUser/hospID"],
          CUser: this.$store.getters["appUser/userID"],
        })
        .then((res) => {
          if (res.Code == 0) {
            this.$store.commit(
              "PdmBodycheckYuyue/setPdmMaApptYuYueCount",
              res.Count
            );
          } else {
            this.msg.error(res.Message);
          }
        });
    },
    ontoggleRightDrawer() {
      this.$emit("toggleRightDrawer");
    },
    onBack() {
      var url = window.location.href.replace("http://", "");
      var ser = url.split("/");
      url = `http://${ser[0]}/#/home`;
      window.location.href = url;
    },
    onLoadLinks() {
      GettGY_Base_LinkList().then((res) => {
        if (res.Code == 0) {
          this.links = res.Data;
        } else {
          msg.error(res.Message);
        }
      });
    },
    onJump(e) {
      window.open(e, "_blank");
    },
  },
};
</script>

<style scoped></style>
